<template>
  <div class="license h100">
    <el-card class="h100 mainCard" id="mainCard">
      <div class="header" style="justify-content: flex-end;">
        <div class="left">
          <el-icon v-show="false" @click="back"><ArrowLeft /></el-icon>
          <span>{{ route.query.sitename }}</span>
        </div>
        <el-input v-model="swid" :suffix-icon="Search" :placeholder="t('inputSwid')" style="width: 180px; margin-right: 6px; color: #FFF;" />
<!--        <el-input v-model="name" :suffix-icon="Search" :placeholder="t('sw_license') + '/' + t('pd_license')" style="width: 233px; margin-right: 6px; color: #FFF;" />-->
        <el-button type="primary" @click="newLicenseKey"> + {{ t('addLicense') }}</el-button>
      </div>

      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%"
        fit
        header-cell-class-name="divTabelHeader"
        row-class-name="divTabelRow"
        cell-class-name="divTabelRowCell"
        :default-sort="{ prop: 'slno', order: 'descending' }"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="slno" :label="t('slno')" width="100" sortable />
<!--        <el-table-column prop="siteid" :label="t('site_Id')"></el-table-column>-->
        <el-table-column prop="swtype" :label="t('sw_type')">
          <template #default="scope">
            {{ !scope.row.swtype || scope.row.swtype !== 'SW' ? 'Max' : 'Pro' }}
          </template>
        </el-table-column>
        <el-table-column prop="swid" :label="t('sw_Id')"></el-table-column>
        <el-table-column prop="swlicense" :label="t('sw_license')"></el-table-column>
        <el-table-column prop="pdlicense" :label="t('pd_license')"></el-table-column>
        <el-table-column :label="t('operations')" width="168" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" @click="deleteLicense(row.slno)">
              {{ t('delete') }}
            </el-button>
            <el-button type="primary" size="small" @click="handleCopyLicense(row.pdlicense || row.swlicense)">
              {{ t('copy') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <AddLicense
      v-if="siteId"
      v-model="addNewLicenseDialogVisible"
      @getListData="getListData"
      :siteId="siteId"
    ></AddLicense>
  </div>
</template>

<script setup>
// 数据相关
import { computed, onActivated, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AddLicense from './components/AddLicense'
import { deleteSiteLicenses } from '@/api/license'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ArrowLeft, Search } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { getCurUserAllLicenses } from '@/api/user'
import commonFunction from '@/utils/commonFunction'

const { t } = useI18n()

// 处理编辑相关
const route = useRoute()
const router = useRouter()
const store = useStore()
const siteId = ref()
onActivated(() => {
  siteId.value = store.getters.userInfo.userId // route.params.id
  getListData()
})

const addNewLicenseDialogVisible = ref(false)
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const swid = ref('')
const name = ref('')
const sort = ref('slno-')

const onSort = computed(() => {
  return sort.value
})

watch(() => [swid.value, name.value, sort.value], () => {
  getListData()
})

const getListData = async () => {
  // await getCurSitesAllLicenses({
  const result = await getCurUserAllLicenses(
    siteId.value,
    {
      page: page.value - 1,
      size: size.value,
      siteId: siteId.value,
      swid: swid.value,
      name: name.value,
      sort: onSort.value
    })

  tableData.value = result.content.list
  total.value = result.content.total
}

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

const handleCopyLicense = (txt) => {
  const { copyText } = commonFunction()
  copyText(txt)
}

const getSortForOrder = (order, prop) => {
  return order.includes('desc') ? prop + '-' : prop + '+'
}

const handleSortChange = ({ order, prop }) => {
  if (['slno'].includes(prop)) {
    sort.value = getSortForOrder(order, prop)
  } else {
    sort.value = 'slno-'
  }
}

// 批量删除
const deleteLicense = async licenseSlno => {
  ElMessageBox.confirm(t('delete_license_confirm'), t('delete_license'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(async () => {
      await deleteSiteLicenses({ licenseSlno })
      ElMessage({
        message: t('delete') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      getListData()
    })
    .catch(() => {})
}

// 在当前site 下创建license key
const newLicenseKey = () => {
  addNewLicenseDialogVisible.value = true
}

const back = () => {
  router.back()
}
onActivated(() => {
  console.log(router, route.query)
})
</script>

<style lang="scss" scoped>
.license {
  .mainCard {
    position: relative;
  }
  ::v-deep .el-card__body {
    height: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .el-icon {
      padding: 14px;
      color: var(--icon-color);
      width: 52px;
      height: 52px;
      border: 2px solid var(--border-el-primary);
      border-radius: 4px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    span {
      font-size: 30px;
      color: var(--font-color-active);
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 700;
      margin-left: 32px;
    }
  }
  ::v-deep .el-table {
    height: calc(100% - 130px);
  }
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  :deep(.el-table__body-wrapper) {
    overflow-y: scroll;
    height: 100%;
  }
}
#mainCard::after {
  content: '';
  background: var(--el-card-border-color);
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 90px;
}
</style>
