<template>
  <div class="license">
    <div>
      <el-table
        :data="tableData"
        :key="tableHeight"
        :height="tableHeight"
        header-cell-class-name="divTabelHeader"
        row-class-name="divTabelRow"
        cell-class-name="divTabelRowCell"
        style="width: 100%"
        size="mini"
      >
        <!--<el-table-column type="selection" width="55" />-->
        <el-table-column prop="slno" :label="t('license_no')" width="100"> </el-table-column>
        <el-table-column prop="siteid" :label="t('user_name')"> </el-table-column>
        <el-table-column prop="swtype" :label="t('sw_type')">
          <template #default="scope">
            {{ !scope.row.swtype || scope.row.swtype !== 'SW' ? 'Max' : 'Pro' }}
          </template>
        </el-table-column>
        <el-table-column prop="swlicense" :label="t('sw_license')"> </el-table-column>
        <el-table-column prop="pdlicense" :label="t('pd_license')"> </el-table-column>
        <el-table-column :label="t('operations')" width="150">
          <template #default="{ row }">
            <!-- <el-button type="primary" size="small" @click="approvalPendingLicense(row)">
              Approval
            </el-button> -->

            <el-button type="danger" size="small" @click="deleteTheSite(row.slno)">
              {{ t('delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
// 数据相关
import { onActivated, ref, onMounted } from 'vue'
import { deleteSiteLicenses, getAllPendingLicenses } from '@/api/license'
import { ElMessage, ElMessageBox } from 'element-plus'
// import { useStore } from 'vuex'
// import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const tableData = ref([])
tableData.value = []
const total = ref(0)
const page = ref(1)
const size = ref(10)
// const store = useStore()
// 获取数据的方法
const getListData = async () => {
  const result = await getAllPendingLicenses({
    page: page.value - 1,
    size: size.value
  })
  tableData.value = result.content.list
  total.value = result.content.total
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// const approvalPendingLicense = async license => {
//   ElMessageBox.confirm('Are you want to Approve the License ?', 'Approve License', {
//     confirmButtonText: 'Confirm',
//     cancelButtonText: 'Cancel',
//     customClass: 'divConfirm',
//     cancelButtonClass: 'divConcancelButton'
//   })
//     .then(() => {
//       ApproveLicense(license)
//     })
//     .catch(() => {})
// }

// async function ApproveLicense(license) {
//   const data = {
//     location: '',
//     requestdate: Number(dayjs().format('YYYYMMDDHHmmss')),
//     status: 1,
//     swtype: license.swtype,
//     userid: store.getters.userInfo.userId,
//     username: store.getters.userInfo.userId
//   }
//   await applypdlicenseapproval(data)
// }

// 批量删除
const deleteTheSite = async licenseId => {
  ElMessageBox.confirm(t('delete_license_confirm'), t('delete_license'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(() => {
      toDeleteLicense(licenseId)
    })
    .catch(() => {})
}

async function toDeleteLicense(licenseId) {
  await deleteSiteLicenses({ licenseSlno: licenseId })
  ElMessage({
    message: t('delete') + t('success'),
    grouping: true,
    type: 'success',
    customClass: 'divSuccessElMessage'
  })
  await getListData()
}

const tableHeight = ref(500)
onMounted(() => {
  tableHeight.value = document.getElementById('mainCard').clientHeight - 193
})
</script>

<style lang="scss" scoped>
.license {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  :deep(.el-table__body-wrapper) {
    overflow-y: scroll;
    height: 100%;
  }
}
</style>
