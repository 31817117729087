<template>
  <div class="user h100">
    <div id="handerBox">
      <div class="search-input">
        <el-input
          v-model="searchTxt"
          :placeholder="t('search_user_name')"
          style="margin-right: 10px"
          :suffix-icon="Search"
        />
        <el-button type="primary" @click="searchUser"> {{ t('search') }}</el-button>
      </div>
      <el-button type="primary" class="ml10" @click="newUser"> {{ t('new_user') }}</el-button>
      <!-- <el-button type="danger" @click="newUser">Del User</el-button> -->
    </div>
    <div>
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%"
        :key="tableHeight"
        :height="tableHeight"
        fit
        header-cell-class-name="divTabelHeader"
        row-class-name="divTabelRow"
        cell-class-name="divTabelRowCell"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column :label="t('slno')" prop="slno" width="100"></el-table-column>
        <el-table-column :label="t('user_name')" prop="userId"></el-table-column>
        <el-table-column prop="approvalstatus" :label="t('approval_status')">
          <template #default="{ row }">
            <div>
              <span>{{ row.approvalstatus ? t('approvaled') : t('waiting_for_approval') }}</span>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="buildingType" :label="t('building_type')"></el-table-column>
        <el-table-column prop="sitename" :label="t('site_name')"></el-table-column>-->
        <el-table-column prop="location" :label="t('location')"></el-table-column>
        <el-table-column prop="password" :label="t('password')"></el-table-column>
        <el-table-column prop="role" :label="t('role')">
          <template #default="{ row }">
            <div>
              <span> {{ row.role === '1' ? t('admin') : t('user') }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('operations')" width="110">
          <template #default="{ row }">
            <!--<el-button type="primary" size="small" @click="updateUserDetail(row._id)">-->
            <!--  Update User-->
            <!--</el-button>-->
            <!--<el-button-->
            <!--  type="primary"-->
            <!--  :disabled="row.approvalstatus === 1"-->
            <!--  size="small"-->
            <!--  @click="approvalTheUser(row.userId)"-->
            <!--&gt;-->
            <!--  Approval User-->
            <!--</el-button>-->
            <el-button type="danger" size="small" @click="deleteTheUser(row.slno)">
              {{ t('delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <AddNewUser v-model="addNewUserDialogVisible" @getListData="getListData"></AddNewUser>
  </div>
</template>

<script setup>
// 数据相关
import { onActivated, ref, onMounted } from 'vue'
import AddNewUser from './AddNewUser.vue'
import { deleteUser, getAllusers } from '@/api/user'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const addNewUserDialogVisible = ref(false)
const searchTxt = ref()
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
// 获取数据的方法
const getListData = async () => {
  const result = await getAllusers({
    page: page.value - 1,
    size: size.value
  })
  tableData.value = result.content.list
  total.value = result.content.total
  // tableData.value = result.list
  // total.value = result.total
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}
// 删除user
const deleteTheUser = async userids => {
  ElMessageBox.confirm(t('delete_Users_confirm'), t('delete_Users'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(() => {
      toDelectUser(userids)
    })
    .catch(() => {})
}

const toDelectUser = async function (userids) {
  await deleteUser({ userids })
  ElMessage({
    message: t('delete') + t('success'),
    grouping: true,
    type: 'success',
    customClass: 'divSuccessElMessage'
  })
  await getListData()
}

const newUser = async () => {
  addNewUserDialogVisible.value = true
}

const searchUser = () => {
  if (searchTxt.value.trim() === '') {
    getListData()
  } else {
    tableData.value = tableData.value.filter(x => x.userId.includes(searchTxt.value))
  }
}

const tableHeight = ref(500)
onMounted(() => {
  tableHeight.value = document.getElementById('mainCard').clientHeight - 193 - 64
})
</script>

<style lang="scss" scoped>
.user {
  .search-input {
    float: left;
    width: 300px;
    display: flex;
  }

  .header {
    margin-bottom: 22px;
    text-align: right;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  :deep(.el-table__body-wrapper) {
    overflow-y: scroll;
    height: 100%;
  }
}
#handerBox {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
